import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'
import withContentfulBlocks from '../components/withContentfulBlocks'

class AboutIndex extends React.Component {
  render () {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    return (
      <ContentfulPage blocks={this.props.blocks}>
        <Helmet title={`About ${siteTitle}`} />
      </ContentfulPage>
    )
  }
}

export default withContentfulBlocks(AboutIndex)

export const pageQuery = graphql`
  query FSAboutQuery {
    site {
      siteMetadata {
        siteName
      }
    }
    page: contentfulPage(contentful_id: { eq: "2LeR5OW4iDYqKD81qsNz2E" }) {
      ...PageBlocksFragment
    }
  }
`
